<template>
  <div class="main">
    <Header/>
    <div class="d-flex align-items-stretch h-100" style="ovflow:hidden">
      <section style="width:208px">
        <el-menu :default-active="$route.path" class="h-100" router >
          <!-- laji iconfont -->
          <el-menu-item index="/team-info/established">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 27px; height: 27px; color: inherit; fill: currentColor;margin-top: 3px;margin-right: 5px">
              <use xlink:href="#fanhan-yichengligonghui"></use>
            </svg>
            <span slot="title">已成立泛团</span>
          </el-menu-item>
          <el-menu-item index="/team-info/to-audit">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 24px; height: 23px; color: inherit; fill: currentColor;margin-top: -4px;margin-right: 9px">
              <use xlink:href="#fanhan-daishenhegonghui"></use>
            </svg>
            <span slot="title">待审核泛团</span>
          </el-menu-item>
          <el-menu-item index="/team-info/refused">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 20px; height: 20px; color: inherit; fill: currentColor;margin: -1px 10px 0px 3px">
              <use xlink:href="#fanhan-beijujuegonghui"></use>
            </svg>
            <span slot="title">被拒绝泛团</span>
          </el-menu-item>
          <el-menu-item index="/team-info/abarbeitung">
            <svg class="icon icon-yuyin" aria-hidden="true" style="width: 17px; height: 17px; color: inherit; fill: currentColor;margin: -1px 10px 0px 6px">
              <use xlink:href="#fanhan-daizhenggaifantuan"></use>
            </svg>
            <span slot="title">待整改泛团</span>
          </el-menu-item>
        </el-menu>
      </section>
      <section class="f-box" style="flex:1;overflow:auto;">
        <router-view />
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
export default {
  name: 'main',
  provide () {
    return {
    }
  },
  components: {
    Header
  },
  methods: {
    // 导航事件
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    // 导航事件
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.f-box{
  padding: 26px 23px 0;
  min-width: 1000px;
}
/deep/.search {
  .el-input__inner {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0;
  }
  .el-button--primary {
    background-color: #3775F6;
    border-color: #3775F6;
    border-radius: 2px;
  }
}
</style>
